import React, { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const AKAHoldingPage = lazy(() => import("./views/AKAHoldingPage"));
const AKAPrivacyPage = lazy(() => import("./views/AKAPrivacyPage"));
const AKACookiePage = lazy(() => import("./views/AKACookiePage"));
const AKATermsPage = lazy(() => import("./views/AKATermsPage"));
const AKADnsspiPage = lazy(() => import("./views/AKADnsspiPage"));

export default function Routing() {
	const location = useLocation();
	const _path = "/";

	const getPageClass = (path) => {
		switch (path) {
			case '/':
				return 'home-page';
			case '/privacy-and-policy':
				return 'privacy-page';
			case '/cookie-policy':
				return 'cookie-page';
			case '/terms-and-conditions':
				return 'terms-page';
			case '/dnsspi-policy':
				return 'dnsspi-policy';
			default:
				return '';
		}
	};

	return (
		<div className={getPageClass(location.pathname)}>
			<Suspense fallback={<div className="loading">Loading...</div>}>
				<Routes>
				<Route exact path={`${_path}`} element={
					<Suspense fallback={<div className="loading">Loading...</div>}>
						<AKAHoldingPage />
					</Suspense>
					} 
				/>
				<Route exact path={`${_path}privacy-and-policy`} element={
					<Suspense fallback={<div className="loading">Loading...</div>}>
						<AKAPrivacyPage />
					</Suspense>
					} 
				/>
				<Route exact path={`${_path}cookie-policy`} element={
					<Suspense fallback={<div className="loading">Loading...</div>}>
						<AKACookiePage />
					</Suspense>
					} 
				/>
				<Route exact path={`${_path}terms-and-conditions`} element={
					<Suspense fallback={<div className="loading">Loading...</div>}>
						<AKATermsPage />
					</Suspense>
					} 
					/>
				<Route exact path={`${_path}dnsspi-policy`} element={
					<Suspense fallback={<div className="loading">Loading...</div>}>
						<AKADnsspiPage />
					</Suspense>
					} 
				/>
				</Routes>
			</Suspense>
		</div>
	);
}
